@import '../../_variables.scss';

.loadIndicator {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 15000;

  &.hidden {
    display: none;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    .loadingContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .spinner {
        position: relative;
        width: 80px;
        height: 80px;
        margin: 0 auto $spacingSmall;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          width: 80px;
          height: 80px;
          background: url(/images/common/load_spinner.png) no-repeat center;
          background-size: contain;
          animation: rotate 1.2s linear infinite;
          margin-top: -40px;
          margin-left: -40px;
        }

        &:after {
          width: 21px;
          height: 36px;
          background-color: #FFF;
          mask: url(/images/icons/flash.svg) no-repeat center;
          mask-size: contain;
          transition: all 0.1s linear;
          opacity: 0.6;
          animation: blink 2.5s linear infinite;
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-360deg);
          }
        }

        @keyframes blink {
          0% {
            opacity: 0.6;
          }
          30% {
            opacity: 0.6;
          }
          50% {
            opacity: 0.75;
          }
          65% {
            opacity: 1;
          }
          80% {
            opacity: 0.6;
          }
          90% {
            opacity: 1;
          }
          100% {
            opacity: 0.6;
          }
        }
      }

      .text {
        font-family: 'Playfair Display';
        font-size: $fontSizeTitle;
        font-weight: 700;
      }
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
