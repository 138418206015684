@import '../_variables.scss';

.baseLayout {
  position: relative;

  & > div {
    transition: filter 0.15s linear;
  }

  .mobileNotice {
    padding: $spacingBasePlus;
    font-size: $fontSizeBasePlus;
    position: sticky;
    display: flex;

    .close {
      width: 40px;
      height: 40px;
      position: relative;
  
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: #FFF;
        mask: url(/images/icons/close.svg) no-repeat center;
        mask-size: contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.navOpen {
    & > div:not(.mobileMenu, .navbar) {
      filter: blur(15px);
    }
  }
}