@import '../../_variables.scss';

.sidebarOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;

  &.hidden {
    display: none;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }

  .close {
    width: 40px;
    height: 40px;
    background-color: $secondaryBackground;
    border-radius: $borderRadiusMain;
    flex-shrink: 0;
    margin-left: $spacingBasePlus;
    cursor: pointer;
    position: fixed;
    top: 25px;
    right: -40px;
    transition: all 0.35s linear;
    opacity: 0;

    &.mobile {
      display: none;
    }

    &.active {
      opacity: 1;
      right: 325px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 16.8px;
      height: 13.6px;
      background-color: #FFF;
      mask: url(/images/icons/close.svg) no-repeat center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .sidebarPane {
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 80;
    background: $thirdColor;
    padding: 30px;
    color: #FFF;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      outline: none;
      width: 0;
      height: 0;
      border-radius: 0;
    }

    &.scrollable {
      overflow-y: scroll;
    }
    &.scrollable::-webkit-scrollbar {
      width: 10px;
    }

    &.mobile {
      width: 100%;
    }

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}
