@import '../../_variables.scss';

.contentPopup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  font-size: 0;

  &.hidden {
    display: none;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    .popupWindow {
      width: 360px;
      display: flex;
      flex-direction: column;
      background-color: $thirdColor;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px, rgba(0, 0, 0, 0.06) 0 2px 12px, rgba(0, 0, 0, 0.04) 0 8px 14px, rgba(0, 0, 0, 0.02) 0 12px 16px;
      max-height: 100%;
      border-radius: 8px;
      transition: all 300ms ease 0s;
      position: relative;
      padding: 22px;

      .close {
        position: absolute;
        right: 22px;
        top: 27px;
        width: 13px;
        height: 13px;
        cursor: pointer;
        background-color: #ffffff;
        mask: url(/images/icons/cross.svg) no-repeat center;
        mask-size: cover;
      }

      .popupTitle {
        font-size: $fontSizeBase;
        font-weight: 600;
        color: #FFF;
        margin-bottom: 20px;
        padding-right: 45px;
      }

      .contentContainer {
        padding: 0;
        font-size: $fontSizeSmall;
        color: $textLightColor;
      }

      .popupControls {
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .popupControl {
          margin-top: 20px;
          margin-left: 20px;
          font-size: $fontSizeBase;
          font-weight: 600;
          color: #FFF;
          height: 40px;
          border-radius: $borderRadiusMain;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $primaryColor;
          cursor: pointer;
          padding: 0 15px;

          &.cancel {
            background-color: transparent;
            border-color: $textDarkGreyColor;
          }

          &.confirm {
            background-color: $primaryColor;
            color: #ffffff;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
